<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Títulos</h1>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md5>
        <v-card elevation="1" class="mt-3">
          <v-simple-table dense>
            <template v-slot:top>
              <v-toolbar flat>
                <div class="flex-grow-1"></div>
                <v-btn class="mr-2" color="primary" elevation="2" x-small @click="add">
                  <v-icon small>mdi-plus</v-icon>Añadir
                </v-btn>
              </v-toolbar>
            </template>

            <template>
              <thead>
                <tr>
                  <th>Codificación Corta</th>
                  <th>Nombre de la tarjeta de transporte</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="item in transportcodes"
                  :key="item.transportcodeId"
                  v-on:click="onTransportcodeSelected(item)"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td class="text-right" style="width:110px">
                    <v-btn outlined color="primary" elevation="2" x-small @click="edit(item)">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      class="ml-1"
                      outlined
                      color="primary"
                      elevation="2"
                      x-small
                      @click="deleteCode(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>

      <v-flex xs12 md7>
        <!--PERIODOS-->
        <v-card elevation="1" class="mt-3 ml-3">
          <v-simple-table dense>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Periodos</v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <div class="flex-grow-1"></div>

                <v-btn
                  class="mr-2"
                  color="primary"
                  elevation="2"
                  x-small
                  v-show="codeSelected.transportcodeId != null"
                  @click="addPeriod"
                >
                  <v-icon small>mdi-plus</v-icon>Añadir
                </v-btn>
              </v-toolbar>
            </template>

            <template>
              <thead>
                <tr>
                  <th>Perfil</th>
                  <th>Colectivo</th>
                  <th>Inicio</th>
                  <th>Tarifa</th>
                  <th>% Bonificación</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="item in codeSelected.periods"
                  :key="item.profileId + item.colectiveId + item.start"
                  v-on:click="onPeriodSelected(item)"
                >
                  <td>{{ getProfileText(item.profileId) }}</td>
                  <td>{{ getColectiveText(item.colectiveId) }}</td>
                  <td>{{ item.start }}</td>
                  <td>{{ item.fare }}</td>
                  <td>{{ item.bonif }}</td>
                  <td class="text-right" style="width:110px">
                    <v-btn outlined color="primary" elevation="2" x-small @click="editPeriod(item)">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      outlined
                      color="primary"
                      elevation="2"
                      x-small
                      class="ml-1"
                      @click="deletePeriod(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <!--MEDIOS DE ACCESO-->
        <v-card elevation="1" class="mt-5 ml-3">
          <v-simple-table dense>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Medios de acceso</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
                <v-btn
                  class="mr-2"
                  color="primary"
                  elevation="2"
                  x-small
                  v-show="codeSelected.transportcodeId != null"
                  @click="addMedia"
                >
                  <v-icon small>mdi-plus</v-icon>Añadir
                </v-btn>
              </v-toolbar>
            </template>

            <template>
              <thead>
                <tr>
                  <th>Medio Acceso</th>
                  <th>Nombre</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="item in codeSelected.mediaAccess"
                  :key="item.mediaAccessId"
                  v-on:click="onMediaSelected(item)"
                >
                  <td>{{ item.mediaAccessId }}</td>
                  <td>{{ item.name }}</td>

                  <td class="text-right">
                    <v-btn
                      outlined
                      color="primary"
                      elevation="2"
                      x-small
                      class="ml-1"
                      @click="deleteMedia(item)"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- EDITOR TITULO -->
    <v-dialog v-model="editorDialog" max-width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2">Editor del Título</v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Codificación Corta"
                      v-model="codeSelected.name"
                      filled
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredCodeRule"
                    >
                      <v-icon slot="append" @click="infoCode">info</v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nombre de la tarjeta de transporte"
                      v-model="codeSelected.description"
                      filled
                      maxlength="250"
                      hide-details="auto"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Descripción corta"
                      v-model="codeSelected.shortDescription"
                      filled
                      hide-details="auto"
                      auto-grow
                      row-height="10"
                      maxlength="1024"
                      :rules="requiredRule"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.modeUse"
                      :items="useModes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Modo de uso"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.modePayment"
                      :items="paymentModes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Modo de pago"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.type"
                      :items="types"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Tipo"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.userType"
                      :items="userTypes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Tipo de usuario"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.cardType"
                      :items="cardTypes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Tipo de tarjeta"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.transferable"
                      :items="transferableTypes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Transferible"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.action"
                      :items="actionTypes"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Acción"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="codeSelected.qrFormat"
                      :items="qrFormats"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Formato QR"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Unidades de carga"
                      v-model="codeSelected.unitsCharge"
                      filled
                      type="number"
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Unidades de recarga"
                      v-model="codeSelected.unitsRecharge"
                      filled
                      type="number"
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Unidades consumo diario"
                      v-model="codeSelected.unitsDay"
                      filled
                      type="number"
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Tiempo máx. renovación QR"
                      v-model="codeSelected.maxtimeReady"
                      filled
                      type="number"
                      maxlength="7"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    >
                      <v-icon slot="append" @click="infoMaxtimeReady">info</v-icon>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="codeSelected.secKey"
                      :items="operator.aesHistory"
                      item-value="secKey"
                      item-text="secKey"
                      filled
                      hide-details="auto"
                      label="Número de clave AES"
                      :rules="requiredRule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="URL imagen"
                      v-model="codeSelected.urlImage"
                      filled
                      maxlength="500"
                      hide-details="auto"
                    >
                      <v-icon slot="append" @click="infoUrlImage" title="Ver clave">info</v-icon>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-show="false">
                  <v-col cols="12">
                    <b class="mx-2">Descripción enriquecida</b>
                    <HtmlEditor :content="codeSelected.richDescription" @input="pruebaKeyup" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Descripción enriquecida"
                      v-model="codeSelected.richDescription"
                      filled
                      hide-details="auto"
                      auto-grow
                      row-height="10"
                    >
                      <v-icon slot="append" @click="infoRichDescription">info</v-icon>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>
        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDITOR PERIODO -->
    <v-dialog v-model="editorPeriod" max-width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2">Pediodo</v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="periodSelected.profileId"
                      :items="profiles"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Perfil"
                      :rules="requiredComboRule"
                      :readonly="editMode"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="periodSelected.colectiveId"
                      :items="colectives"
                      item-value="id"
                      item-text="description"
                      filled
                      hide-details="auto"
                      label="Colectivos"
                      :rules="requiredComboRule"
                      :readonly="editMode"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="start"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="periodSelected.start"
                          label="Fecha Inicio"
                          filled
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          :rules="[v => !!v || 'La fecha de inicio es obligatoria.']"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateStart"
                        no-title
                        :readonly="editMode"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="end"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="periodSelected.end"
                          label="Fecha fin"
                          filled
                          clearable
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateEnd"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Tarifa del título"
                      v-model="periodSelected.fare"
                      type="number"
                      filled
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="% Bonificación tarifa base"
                      v-model="periodSelected.bonif"
                      type="number"
                      filled
                      maxlength="5"
                      hide-details="auto"
                      :rules="requiredNumberRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>
        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="savePeriod"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorPeriod = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDITOR MEDIO ACCESO -->
    <v-dialog v-model="editorMedia" max-width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2">Medio de acceso</v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-select
                    v-model="mediaSelected.mediaAccessId"
                    :items="operatorMediaAccess"
                    item-value="mediaAccessId"
                    item-text="name"
                    filled
                    hide-details="auto"
                    label="Medio de acceso"
                    :readonly="editMode"
                    :rules="[v => !!v || 'El medio de acceso es obligatorio.']"
                  ></v-select>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>
        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveMedia"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorMedia = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-confirm-dialog></vue-confirm-dialog>
    <CustomDialog></CustomDialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import HtmlEditor from "../components/HtmlEditor.vue";
import CustomDialog from "../components/CustomDialog.vue";
import VueConfirmDialog from "vue-confirm-dialog";

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

const catalogs = require("../shared/catalogs");
const validation = require("../shared/validation");
export default {
  name: "Transportcodes",
  components: { HtmlEditor, CustomDialog },
  data: () => ({
    useModes: catalogs.useModes,
    paymentModes: catalogs.paymentModes,
    userTypes: catalogs.userTypes,
    cardTypes: catalogs.cardTypes,
    types: catalogs.types,
    transferableTypes: catalogs.transferableTypes,
    actionTypes: catalogs.actionTypes,
    qrFormats: catalogs.qrFormats,
    start: false,
    end: false,
    dateStart: null,
    dateEnd: null,

    requiredRule: [v => (v != null && v != "") || "Campo obligatorio"],
    requiredNumberRule: [v => (v != null && v != "" && v >= 0) || "Campo obligatorio"],
    requiredCodeRule: [
      v => (v != null && v != "" && v.length == 5) || "Campo obligatorio y de 5 caracteres",
    ],
    requiredComboRule: [v => v >= 0 || "Campo obligatorio"],

    editorDialog: false,
    editMode: false,
    codeSelected: {},

    editorPeriod: false,
    periodSelected: {},

    editorMedia: false,
    mediaSelected: {},
  }),
  created() {
    this.$store.dispatch("fetchTransportcodes");
    this.$store.dispatch("fetchColectives");
    this.$store.dispatch("fetchProfiles");
  },
  watch: {
    dateStart() {
      this.periodSelected.start = validation.formatDate(this.dateStart);
    },
    dateEnd() {
      this.periodSelected.end = validation.formatDate(this.dateEnd);
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    operator() {
      return this.$store.getters.getOperator;
    },
    transportcodes() {
      return this.$store.getters.getTransportcodes;
    },
    colectives() {
      return this.$store.getters.getColectives;
    },
    profiles() {
      return this.$store.getters.getProfiles;
    },
    operatorMediaAccess() {
      return this.$store.getters.getMediaAccess;
    },
  },
  methods: {
    getColectiveText: function(colectiveId) {
      var valObj = this.colectives.filter(function(elem) {
        if (elem.id == colectiveId) return elem.description;
      });
      if (valObj.length > 0) return valObj[0].description;
      else return "";
    },
    getProfileText: function(profileId) {
      var valObj = this.profiles.filter(function(elem) {
        if (elem.id == profileId) return elem.description;
      });
      if (valObj.length > 0) return valObj[0].description;
      else return "";
    },
    add: function() {
      this.codeSelected = {
        secKey: this.operator.aesHistory[this.operator.aesHistory.length - 1].secKey,
        unitsCharge: "0",
        unitsRecharge: "0",
        unitsDay: "0",
        maxtimeReady: "0",
      };
      this.editorDialog = true;
      this.editMode = false;
    },
    edit: function(item) {
      this.codeSelected = item;
      this.editorDialog = true;
      this.editMode = true;
    },
    onTransportcodeSelected: function(item) {
      this.codeSelected = item;
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.codeSelected.name = this.codeSelected.name.toUpperCase();
        this.$store.dispatch("saveTransportcode", this.codeSelected).then(
          response => {
            if (!this.editMode) this.$store.commit("appendTransportcode", response);
            this.editorDialog = false;
          },
          () => {
            this.editorDialog = true;
          }
        );
      }
    },
    deleteCode: function(item) {
      this.$confirm({
        title: "Eliminar título",
        message: "¿Deseas eliminar el título, sus periodos y Medios de Acceso?",
        button: {
          yes: "Sí",
          no: "No",
        },
        callback: confirm => {
          if (confirm) {
            this.$store.dispatch("deleteTransportcode", item).then(() => {
              this.$store.dispatch("fetchTransportcodes");
            });
          }
        },
      });
    },
    addPeriod: function() {
      this.periodSelected = { fare: "0", bonif: "0" };
      this.editorPeriod = true;
      this.editMode = false;
    },
    editPeriod: function(item) {
      this.periodSelected = item;
      this.editorPeriod = true;
      this.editMode = true;
      if (item.start != null) {
        this.dateStart = validation.parseDate(item.start);
      }
      if (item.end != null) {
        this.dateEnd = validation.parseDate(item.end);
      }
    },
    onPeriodSelected: function(item) {
      this.periodSelected = item;
    },
    savePeriod: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.periodSelected.transportcodeId = this.codeSelected.transportcodeId;
        this.$store.dispatch("saveTransportcodePeriod", this.periodSelected).then(response => {
          if (!this.editMode) this.codeSelected.periods.push(response);
          this.editorPeriod = false;
        });
      }
    },
    deletePeriod: function(item) {
      this.$confirm({
        message: "¿Deseas borrar el periodo?",
        button: {
          yes: "Sí",
          no: "No",
        },
        callback: confirm => {
          if (confirm) {
            item.transportcodeId = this.codeSelected.transportcodeId;
            this.$store.dispatch("deleteTransportcodePeriod", item).then(() => {
              this.codeSelected.periods.splice(this.codeSelected.periods.indexOf(item), 1);
            });
          }
        },
      });
    },
    addMedia: function() {
      this.mediaSelected = {};
      this.editorMedia = true;
      this.editMode = false;
    },
    deleteMedia: function(item) {
      this.$confirm({
        message: "¿Deseas borrar el Medio de Acceso?",
        button: {
          yes: "Sí",
          no: "No",
        },
        callback: confirm => {
          if (confirm) {
            item.transportcodeId = this.codeSelected.transportcodeId;
            this.$store.dispatch("deleteTransportcodeMediaAccess", item).then(() => {
              this.codeSelected.mediaAccess.splice(this.codeSelected.mediaAccess.indexOf(item), 1);
            });
          }
        },
      });
    },
    onMediaSelected: function(item) {
      this.mediaSelected = item;
    },
    saveMedia: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.mediaSelected.transportcodeId = this.codeSelected.transportcodeId;
        this.$store.dispatch("saveTransportcodeMediaAccess", this.mediaSelected).then(response => {
          if (!this.editMode) this.codeSelected.mediaAccess.push(response);
          this.editorMedia = false;
        });
      }
    },
    infoCode: function() {
      this.$store.commit("showCustomDialog", {
        title: "Codificación corta",
        body:
          "Este valor puedes utilizarlo para realizar una codificación numérica o texto en la que reflejes, por ejemplo, el identificador del título de transportes en el sistema del Operador.",
      });
    },
    infoMaxtimeReady: function() {
      this.$store.commit("showCustomDialog", {
        title: "Tiempo máximo de renovación QR",
        body:
          "Indica el tiempo máximo en segundos que un QR será válido para el dispositivo de lectura antes de requerir un refresco de su contenido.",
      });
    },
    infoUrlImage: function() {
      this.$store.commit("showCustomDialog", {
        title: "URL Imagen",
        body:
          "Por favor, pega aquí la URL de la imagen de la tarjeta de Transportes asociada. Esta imagen podrás recuperarla posteriormente a través de la API MPass con los propósitos que requieras.",
      });
    },
    infoRichDescription: function() {
      this.$store.commit("showCustomDialog", {
        title: "Descripción enriquecida",
        body:
          "Opcionalmente, puedes utilizar un editor HTML para construir una descripción que luego puedas representar en formato enriquecido en una Web o utilizando un Webview de una aplicación móvil.",
      });
    },
    pruebaKeyup: function(value) {
      this.codeSelected.richDescription = value;
    },
  },
};
</script>

<style scoped></style>
