<template>
  <v-dialog v-model="visible" max-width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ title }}
      </v-card-title>
      <container>
        <v-card-text>
          {{ body }}
        </v-card-text>
      </container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="visible = false"> Aceptar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CustomDialog",
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showCustomDialog") {
        this.title = state.customDialog.title;
        this.body = state.customDialog.body;
        this.visible = true;
      }
    });
  },
  data: () => ({
    title: "",
    body: "",
    visible: false,
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
