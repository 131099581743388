<template>
  <tiptap-vuetify class="my-2" v-model="content" :extensions="extensions" @keydown="updateValue" />
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Paragraph,
  Bold,
  Italic,
  Underline,
  Link,
  Image,
  HorizontalRule,
} from "tiptap-vuetify";

export default {
  name: "HtmlEditor",
  components: { TiptapVuetify },
  props: ["content"],
  created() {
    // Mutating the prop :(
    this.content = this.content.reverse();
  },
  data: () => ({
    extensions: [
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Paragraph,
      Bold,
      Italic,
      Underline,
      Link,
      Image,
      HorizontalRule,
    ],
  }),
  computed: {},
  methods: {
    updateValue: function() {
      this.$emit("input", this.content);
    },
  },
};
</script>

<style scoped></style>
